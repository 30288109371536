import { FC } from 'react';
import Image from 'next/image';
import { Button, Flex, HStack, ModalBody, Text, VStack } from '@chakra-ui/react';
import CustomRadio from '@/src/components/UI/CustomRadio';
import { ListingSortingType, useFilterListingContextProvider } from '@/src/context/FilterListingContext';

export const sortingOptions = [
  {
    value: ListingSortingType.NONE,
    label: '預設',
  },
  {
    value: ListingSortingType.CURRENT_DEPLOYED_AT_ASC,
    label: '上架日期舊到新',
  },
  {
    value: ListingSortingType.CURRENT_DEPLOYED_AT_DESC,
    label: '上架日期新到舊',
  },
  {
    value: ListingSortingType.PRICE_ASC,
    label: '價格從低到高',
  },
  {
    value: ListingSortingType.PRICE_DESC,
    label: '價格從高到低',
  },
];

const BuyPageFilterModal: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { sortingType, onSortingTypeSelect, applyFilter } = useFilterListingContextProvider();

  return (
    <ModalBody
      display={'flex'}
      position={{ base: 'absolute', md: 'relative' }}
      bottom={{ base: 0 }}
      left={{ base: 0 }}
      px={{ base: 0 }}
      py={{ base: 4, md: 0 }}
      height={{ base: 'fit-content', md: 'auto' }}
      maxHeight={{ base: '80vh' }}
      width={{ md: '624px' }}
      minWidth={{ md: 'auto', base: '100vw' }}
      backgroundColor={'white'}
      className={'shadow-dropdown overflow-hidden'}
      del
    >
      <Flex w={'full'} flexDirection={'column'} className={'flex-1 overflow-hidden'}>
        <Flex flexDirection={'column'} w={'full'} py={{ md: 4 }} className={'flex-1 overflow-hidden'}>
          <Flex
            px={{ md: 8, base: 6 }}
            display={{ md: 'none' }}
            alignItems={'center'}
            justifyContent={'center'}
            className={'relative py-1'}
            mb={4}
          >
            <Flex
              as={'button'}
              onClick={onClose}
              className={'absolute top-0 left-6 md:left-8 w-6 h-10 py-2'}
              alignItems={'center'}
            >
              <Image src={'/close-outline.svg'} layout={'fixed'} width={24} height={24} />
            </Flex>
            <Text className={'text-lg text-center font-medium'}>搜尋排序</Text>
          </Flex>
          <VStack w={'full'} className={'flex-1 overflow-hidden'}>
            <VStack w={'full'} className={'-mx-6 md:mx-0'}>
              <CustomRadio
                activeIdx={sortingType}
                onChange={onSortingTypeSelect}
                defaultIdx={0}
                options={sortingOptions}
                disableDesktopExtraStyle
              />
            </VStack>
          </VStack>
        </Flex>
        <HStack
          w={'full'}
          justify={'space-between'}
          borderTop={1}
          borderStyle={'solid'}
          borderColor={'gray.300'}
          py={{ md: 4, base: 2 }}
          px={{ md: 8, base: 6 }}
          className={'flex-shrink-0'}
        >
          <Button
            onClick={() => {
              applyFilter();
              onClose();
            }}
            fontSize={{ base: '16px' }}
            px={0}
            m={0}
            w={'full'}
          >
            <Text fontWeight={'500'}>套用</Text>
          </Button>
        </HStack>
      </Flex>
    </ModalBody>
  );
};
export default BuyPageFilterModal;
