import React, { FC } from 'react';
import Image from 'next/image';
import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react';

import { useFilterListingContextProvider } from '../../context/FilterListingContext';

import { BuildingTypeFilterModalProps } from './BuildingTypeFilterModalProps';

import ConditionalFragment from '@/components/common/ConditionalFragment';
import BuyPageFilterModal from './BuyPageFilterModal';
import { ListingStatus } from '@/swagger';
import { getListingStatusTitleString } from '@/src/components/UI/FilterContainer';
import CustomRadio from '@/src/components/UI/CustomRadio';
import classNames from 'classnames';

const BuildingTypeFilterModal: FC<BuildingTypeFilterModalProps> = ({ buildingTypesData, onClose }) => {
  const {
    buildTypeFilterResult,
    listingStatusType,
    onBuildingTypeSelect,
    resetBuildingTypeFilterResult,
    onListingStatusTypeSelect,
  } = useFilterListingContextProvider();

  return (
    <BuyPageFilterModal onClose={onClose} onReset={resetBuildingTypeFilterResult}>
      <div className={classNames('-mx-6 md:mx-0 md:mb-4 w-screen md:w-full', 'flex flex-col flex-1 overflow-hidden')}>
        <div className={'w-full'}>
          <div className={'px-4 md:px-0'}>
            <p className={'text-lg md:text-base font-bold md:font-medium text-gray900 underline md:no-underline'}>
              類型
            </p>
          </div>
          <Box w={'full'} display={'flex'} flexWrap={'wrap'} flexDirection={{ base: 'column', md: 'row' }}>
            {buildingTypesData.map((type) => {
              const isSelected = buildTypeFilterResult.includes(type);

              return (
                <Button
                  py={{ md: 1, base: 4 }}
                  px={{ md: undefined, base: 4 }}
                  pr={{ md: 4 }}
                  height={'auto'}
                  bgColor={'transparent'}
                  key={type}
                  onClick={() => {
                    onBuildingTypeSelect(type);
                  }}
                >
                  <Flex
                    align={'center'}
                    justify={{ md: 'center', base: 'space-between' }}
                    flexDirection={{ md: 'row', base: 'row-reverse' }}
                    w={'full'}
                    borderColor={isSelected ? 'black' : 'gray.500'}
                  >
                    <Stack
                      alignItems={'center'}
                      justifyContent={'center'}
                      position={'relative'}
                      mr={{ md: 2 }}
                      width={18}
                      height={18}
                      borderRadius={4}
                      border={'2px solid black'}
                    >
                      <ConditionalFragment condition={isSelected}>
                        <Image src={'/check-icon.svg'} layout={'fill'} />
                      </ConditionalFragment>
                    </Stack>
                    <Text fontSize={16} lineHeight={'24px'} letterSpacing={0.5} fontWeight={'400'} textColor={'black'}>
                      {type}
                    </Text>
                  </Flex>
                </Button>
              );
            })}
          </Box>
        </div>
        <div className={'w-full'}>
          <div className={'px-4 md:px-0'}>
            <p className={'text-lg md:text-base font-bold md:font-medium text-gray900 underline md:no-underline'}>
              狀態
            </p>
          </div>
          <CustomRadio
            disableDesktopExtraStyle
            activeIdx={listingStatusType}
            onChange={onListingStatusTypeSelect}
            defaultIdx={0}
            options={[
              { label: getListingStatusTitleString(ListingStatus.ON_SALE)!, value: ListingStatus.ON_SALE },
              { label: getListingStatusTitleString(ListingStatus.SOLD)!, value: ListingStatus.SOLD },
            ]}
          />
        </div>
      </div>
    </BuyPageFilterModal>
  );
};
export default BuildingTypeFilterModal;
