import { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { BuyPageSearchParams, PositionProps } from '@/pages/buy';
import FunwooAPI, { CountryEnum, ListingStatus } from '@/swagger';
import { useRouter } from 'next/router';
import { searchParamParser } from '@/utils/searchParamParser';

export type Country = CountryEnum | 'ALL';

export enum ListingSortingType {
  NONE = 'NONE',
  PRICE_DESC = 'PRICE_DESC',
  PRICE_ASC = 'PRICE_ASC',
  CURRENT_DEPLOYED_AT_ASC = 'CURRENT_DEPLOYED_AT_ASC',
  CURRENT_DEPLOYED_AT_DESC = 'CURRENT_DEPLOYED_AT_DESC',
}

const FilterListingContext = createContext(
  {} as {
    areaFilterResult: {
      cities: Array<string>;
      country: Country;
    };
    finialAreaFilterResult: {
      cities: Array<string>;
      country: Country;
    };
    sortingType: ListingSortingType;
    finalSortingType: ListingSortingType;
    listingStatusType: ListingStatus | null;
    finalListingStatusType: ListingStatus | null;
    buildTypeFilterResult: string[];
    finialBuildTypeFilterResult: string[];
    onCountrySelect: (country: Country) => void;
    onCitySelect: (city: string) => void;
    onBuildingTypeSelect: (buildType: string) => void;
    onSortingTypeSelect: (type: ListingSortingType) => void;
    onListingStatusTypeSelect: (type: ListingStatus) => void;
    resetAreaFilterResult: () => void;
    resetBuildingTypeFilterResult: () => void;
    onRemoveBuildType: (buildType: string, apply?: boolean) => void;
    onRemoveCity: (city: string, apply?: boolean) => void;
    applyFilter: () => void;
    resultNum: number;
    setBuildTypeFilterResult: (value: string[]) => void;
    setAreaFilterResult: (value: PositionProps) => void;
    setSortingType: (type: ListingSortingType) => void;
  }
);

const FilterListingContextProvider: FC = ({ children }) => {
  const router = useRouter();
  const {
    cities = [],
    buildingType = [],
    country,
    sortBy,
    listingStatus = null,
  } = router.query as ReplaceValueType<BuyPageSearchParams, Array<string>, string | Array<string>>;

  const [resultNum, setResultNum] = useState(0);
  const [areaFilterResult, setAreaFilterResult] = useState<PositionProps>({
    cities: Array.isArray(cities) ? cities : [cities],
    country: searchParamParser(country as CountryEnum | Array<CountryEnum>)?.[0] ?? 'ALL',
  });
  const [finialAreaFilterResult, setFinalAreaFilterResult] = useState<PositionProps>({
    cities: Array.isArray(cities) ? cities : [cities],
    country: searchParamParser(country as CountryEnum | Array<CountryEnum>)?.[0] ?? 'ALL',
  });
  const [buildTypeFilterResult, setBuildTypeFilterResult] = useState<string[]>(
    Array.isArray(buildingType) ? buildingType : [buildingType]
  );
  const [finialBuildTypeFilterResult, setFinialBuildTypeFilterResult] = useState<string[]>(
    Array.isArray(buildingType) ? buildingType : [buildingType]
  );
  const [sortingType, setSortingType] = useState<ListingSortingType>(sortBy ?? ListingSortingType.NONE);
  const [finalSortingType, setFinalSortingType] = useState<ListingSortingType>(sortBy ?? ListingSortingType.NONE);

  const [listingStatusType, setListingStatusType] = useState<ListingStatus | null>(null);
  const [finalListingStatusType, setFinalListingStatusType] = useState<ListingStatus | null>(null);

  useEffect(() => {
    setAreaFilterResult({
      cities: Array.isArray(cities) ? cities : [cities],
      country: searchParamParser(country as CountryEnum | Array<CountryEnum>)?.[0] ?? 'ALL',
    });
    setFinalAreaFilterResult({
      cities: Array.isArray(cities) ? cities : [cities],
      country: searchParamParser(country as CountryEnum | Array<CountryEnum>)?.[0] ?? 'ALL',
    });
    setBuildTypeFilterResult(Array.isArray(buildingType) ? buildingType : [buildingType]);
    setFinialBuildTypeFilterResult(Array.isArray(buildingType) ? buildingType : [buildingType]);
    setSortingType(sortBy ?? ListingSortingType.NONE);
    setFinalSortingType(sortBy ?? ListingSortingType.NONE);
    setListingStatusType(listingStatus);
    setFinalListingStatusType(listingStatus);
  }, [router.query]);

  const applyFilter = async () => {
    setFinalAreaFilterResult(areaFilterResult);
    setFinialBuildTypeFilterResult(buildTypeFilterResult);
    setFinalSortingType(sortingType);
    setFinalListingStatusType(listingStatusType);
    const { country, cities } = areaFilterResult;
    let query: BuyPageSearchParams = {
      ...(router.query ?? {}),
      cities,
      country: [country as CountryEnum],
      buildingType: buildTypeFilterResult,
      sortBy: sortingType,
      listingStatus: listingStatusType ?? undefined,
    };
    if (country === 'ALL') {
      delete query.country;
    }
    if (sortingType === ListingSortingType.NONE) {
      delete query.sortBy;
    }
    if (!listingStatusType) {
      delete query.listingStatus;
    }

    await router.replace({
      pathname: '/buy',
      query: {
        ...query,
      },
    });
  };
  useEffect(() => {
    (async () => {
      const {
        data: { totalCount },
      } = await FunwooAPI.listingApi.searchCount({
        paging: {
          page: 1,
          pageSize: 12,
        },
        country: areaFilterResult.country !== 'ALL' ? [areaFilterResult.country] : undefined,
        cities: areaFilterResult.cities,
        buildingType: buildTypeFilterResult,
        listingStatus: listingStatusType ?? undefined,
      });
      setResultNum(totalCount || 0);
    })();
  }, [areaFilterResult, buildTypeFilterResult, listingStatusType]);

  const onCountrySelect = (country: Country) => {
    setAreaFilterResult({ country: country, cities: [] });
  };

  const onAddNewBuildType = (buildType: string) => {
    setBuildTypeFilterResult([...buildTypeFilterResult, buildType]);
  };
  const onRemoveBuildType = (buildType: string, apply?: boolean) => {
    const temp = [...buildTypeFilterResult];
    const idx = temp.indexOf(buildType);
    if (idx != -1) {
      temp.splice(idx, 1);
      setBuildTypeFilterResult(temp);
      if (apply) {
        setFinialBuildTypeFilterResult(temp);
      }
    }
  };
  const onBuildingTypeSelect = (buildType: string) => {
    const temp = [...buildTypeFilterResult];
    const idx = temp.indexOf(buildType);
    if (idx != -1) {
      onRemoveBuildType(buildType);
    } else {
      onAddNewBuildType(buildType);
    }
  };
  const onAddNewCity = (city: string) => {
    setAreaFilterResult({ ...areaFilterResult, cities: [...areaFilterResult.cities, city] });
  };
  const onRemoveCity = (city: string, apply?: boolean) => {
    const temp = [...areaFilterResult.cities];
    const idx = temp.indexOf(city);
    if (idx !== -1) {
      temp.splice(idx, 1);
      setAreaFilterResult({ ...areaFilterResult, cities: temp });
      if (apply) {
        setFinalAreaFilterResult({ ...areaFilterResult, cities: temp });
      }
    }
  };
  const onCitySelect = (city: string) => {
    const temp = [...areaFilterResult.cities];
    const idx = temp.indexOf(city);
    if (idx === -1) {
      onAddNewCity(city);
    } else {
      onRemoveCity(city);
    }
  };
  const onSortingTypeSelect = useCallback((type: ListingSortingType) => {
    setSortingType(type);
  }, []);
  const onListingStatusTypeSelect = useCallback((type: ListingStatus) => {
    setListingStatusType((prev) => (prev === type ? null : type));
  }, []);
  const resetAreaFilterResult = () => {
    setAreaFilterResult({
      cities: [],
      country: 'ALL',
    });
  };
  const resetBuildingTypeFilterResult = () => {
    setBuildTypeFilterResult([]);
    setListingStatusType(null);
  };

  return (
    <FilterListingContext.Provider
      value={{
        onCountrySelect,
        areaFilterResult,
        finialAreaFilterResult,
        sortingType,
        finalSortingType,
        onSortingTypeSelect,
        listingStatusType,
        finalListingStatusType,
        onListingStatusTypeSelect,
        onCitySelect,
        onBuildingTypeSelect,
        resetAreaFilterResult,
        buildTypeFilterResult,
        finialBuildTypeFilterResult,
        resetBuildingTypeFilterResult,
        onRemoveBuildType,
        onRemoveCity,
        applyFilter,
        resultNum,
        setBuildTypeFilterResult,
        setAreaFilterResult,
        setSortingType,
      }}
    >
      {children}
    </FilterListingContext.Provider>
  );
};
const useFilterListingContextProvider = () => {
  return useContext(FilterListingContext);
};
export { FilterListingContextProvider, useFilterListingContextProvider };
