import React, { FC, useCallback, useMemo, useRef } from 'react';
import { Box, Button, Flex, Stack, Text, VStack } from '@chakra-ui/react';
import Image from 'next/image';

import { Country, useFilterListingContextProvider } from '../../context/FilterListingContext';

import ConditionalFragment from '@/components/common/ConditionalFragment';
import CustomRadio from './CustomRadio';
import BuyPageFilterModal from './BuyPageFilterModal';
import { BuyFilterAreaModalProps } from './BuyFilterAreaModalTypes';
import { AreaOptions, CountryEnum } from '@/swagger';
import { CountryEnum as CountryLabelEnum } from '@/constants/country';
import useIsTopOrEndReached from '@/src/hooks/useIsTopOrEndReached';
import classNames from 'classnames';
import ScrollableMaskContainer from '@/components/common/ScrollableMaskContainer';
import { useAsync } from 'react-use';
import { locationData } from '@/lib/constant';

const countryData: Array<Country> = ['ALL', CountryEnum.TW, CountryEnum.US];

const BuyFilterAreaModal: FC<BuyFilterAreaModalProps> = ({ citiesData, usCitiesData, onClose }) => {
  const { areaFilterResult, onCountrySelect, resetAreaFilterResult } = useFilterListingContextProvider();
  const { country } = areaFilterResult;

  const hasUSCities = usCitiesData.length > 0;

  const countryOptions = useMemo<Array<Option<Country>>>(() => {
    return countryData.map((country) => ({
      value: country,
      label: CountryLabelEnum[country as keyof typeof CountryEnum],
    }));
  }, [countryData]);

  return (
    <BuyPageFilterModal onClose={onClose} onReset={resetAreaFilterResult}>
      <div className={classNames('-mx-6 md:mb-4 w-screen md:w-full', 'flex flex-col flex-1 overflow-hidden')}>
        <VStack w={'full'}>
          <CustomRadio
            disableDesktopExtraStyle
            activeIdx={country}
            onChange={onCountrySelect}
            defaultIdx={0}
            options={countryOptions}
          />
        </VStack>
        <ConditionalFragment condition={country === 'TW' || (country === 'US' && hasUSCities)}>
          <Stack bgColor={'gray.200'} w={'full'} h={'1px'} my={{ md: '4' }} className={'px-6 flex-shrink-0'} />
        </ConditionalFragment>
        <div className={classNames('px-4 w-screen md:w-full', 'relative flex flex-col overflow-scroll')}>
          {country === 'TW' && <CityOptions data={citiesData} />}
          {country === 'US' && <CitySectionOptions data={usCitiesData} />}
        </div>
      </div>
    </BuyPageFilterModal>
  );
};
export default BuyFilterAreaModal;

const CitySectionOptions: React.FC<{ data: Array<AreaOptions> }> = ({ data }) => {
  const { value: location } = useAsync(() => locationData, []);
  const { areaFilterResult, onCitySelect } = useFilterListingContextProvider();
  const { cities } = areaFilterResult;

  const getStateTitle = useCallback(
    (key: string) => {
      const usLocation = location?.US;
      if (!usLocation) return '';
      const target = usLocation.find((state) => state.key === key);
      if (!target) return '';
      return `${target.zh_tw} (${target.en})`;
    },
    [location]
  );

  if (!location) return null;

  return (
    <div className={'-mx-4 h-full'}>
      <ScrollableMaskContainer className={'space-y-2 p-4 md:py-0'} direction={'vertical'}>
        {data.map((state) => {
          return (
            <div key={state.state}>
              <p className={'text-lg md:text-base font-bold md:font-medium text-gray900 underline md:no-underline'}>
                {getStateTitle(state.state)}
              </p>
              <Box
                id={'city-option-container'}
                w={'full'}
                display={'flex'}
                flexWrap={{ md: 'wrap' }}
                flexDirection={{ base: 'column', md: 'row' }}
                className={'relative overflow-scroll'}
              >
                {state.cities.map((city) => {
                  const isSelected = cities.includes(city);

                  return (
                    <Button
                      p={{ base: 0 }}
                      py={{ md: 1, base: 4 }}
                      pr={{ md: 4 }}
                      height={'auto'}
                      bgColor={'transparent'}
                      key={city}
                      onClick={() => {
                        onCitySelect(city);
                      }}
                    >
                      <Flex
                        align={'center'}
                        justify={{ md: 'center', base: 'space-between' }}
                        flexDirection={{ md: 'row', base: 'row-reverse' }}
                        w={'full'}
                        borderColor={isSelected ? 'black' : 'gray.500'}
                      >
                        <Stack
                          border={'2px solid black'}
                          position={'relative'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          mr={{ md: 2 }}
                          width={18}
                          height={18}
                          borderRadius={4}
                        >
                          <ConditionalFragment condition={isSelected}>
                            <Image src={'/check-icon.svg'} layout={'fill'} />
                          </ConditionalFragment>
                        </Stack>
                        <Text
                          fontSize={16}
                          lineHeight={'24px'}
                          letterSpacing={0.5}
                          fontWeight={'400'}
                          textColor={'black'}
                        >
                          {city}
                        </Text>
                      </Flex>
                    </Button>
                  );
                })}
              </Box>
            </div>
          );
        })}
      </ScrollableMaskContainer>
    </div>
  );
};

const CityOptions: React.FC<{ data: Array<string> }> = ({ data }) => {
  const boxRef = useRef<HTMLDivElement | null>(null);

  const { onContainerScroll, bottomEndReached, topEndReached } = useIsTopOrEndReached({ container: boxRef.current });
  const { areaFilterResult, onCitySelect } = useFilterListingContextProvider();
  const { cities } = areaFilterResult;

  return (
    <React.Fragment>
      <Box
        ref={boxRef}
        onScroll={onContainerScroll}
        id={'city-option-container'}
        w={'full'}
        display={'flex'}
        flexWrap={{ md: 'wrap' }}
        flexDirection={{ base: 'column', md: 'row' }}
        className={'relative overflow-scroll'}
      >
        {data.map((city) => {
          const isSelected = cities.includes(city);

          return (
            <Button
              p={{ base: 0 }}
              py={{ md: 1, base: 4 }}
              pr={{ md: 4 }}
              height={'auto'}
              bgColor={'transparent'}
              key={city}
              onClick={() => {
                onCitySelect(city);
              }}
            >
              <Flex
                align={'center'}
                justify={{ md: 'center', base: 'space-between' }}
                flexDirection={{ md: 'row', base: 'row-reverse' }}
                w={'full'}
                borderColor={isSelected ? 'black' : 'gray.500'}
              >
                <Stack
                  border={'2px solid black'}
                  position={'relative'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  mr={{ md: 2 }}
                  width={18}
                  height={18}
                  borderRadius={4}
                >
                  <ConditionalFragment condition={isSelected}>
                    <Image src={'/check-icon.svg'} layout={'fill'} />
                  </ConditionalFragment>
                </Stack>
                <Text fontSize={16} lineHeight={'24px'} letterSpacing={0.5} fontWeight={'400'} textColor={'black'}>
                  {city}
                </Text>
              </Flex>
            </Button>
          );
        })}
      </Box>
      <div
        className={classNames('md:invisible md:hidden absolute top-0 left-0 w-full h-16 pointer-events-none', {
          'block visible': !topEndReached,
          'hidden invisible': topEndReached,
        })}
        style={{
          backgroundImage: 'linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.4) 100%)',
        }}
      />
      <div
        className={classNames('md:invisible md:hidden absolute bottom-0 left-0 w-full h-16 pointer-events-none', {
          'block visible': !bottomEndReached,
          'hidden invisible': bottomEndReached,
        })}
        style={{
          backgroundImage: 'linear-gradient(to top, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.4) 100%)',
        }}
      />
    </React.Fragment>
  );
};
