import { FC, Fragment, useMemo, useRef } from 'react';
import { Button, Flex, HStack, Modal, ModalContent, Stack, Text, useDisclosure } from '@chakra-ui/react';
import classNames from 'classnames';

import { ListingSortingType, useFilterListingContextProvider } from '../../context/FilterListingContext';
import BuildingTypeFilterModal from './BuildingTypeFilterModal';
import BuyFilterAreaModal from './BuyFilterAreaModal';
import { CountryEnum } from '@/constants/country';
import { BsFilterLeft } from 'react-icons/bs';
import BuyPageSortingModal, { sortingOptions } from '@/src/components/UI/BuyPageSortingModal';
import { AreaOptions, ListingStatus } from '@/swagger';

const getTop = (el: HTMLElement): number => el.getBoundingClientRect().top + el.clientHeight + 8;
const getLeft = (el: HTMLElement): number => el.getBoundingClientRect().left;
const getRight = (el: HTMLElement): number => window.innerWidth - el.getBoundingClientRect().right;

export const getListingStatusTitleString = (type: ListingStatus | null) => {
  switch (type) {
    case ListingStatus.SOLD:
      return '已售';
    case ListingStatus.ON_SALE:
      return '在售';
    default:
      return null;
  }
};

const FilterContainer: FC<{
  citiesData: Array<string>;
  usCitiesData: AreaOptions[];
  resultNum: number;
  buildingTypesData: string[];
}> = ({ buildingTypesData, citiesData, usCitiesData, resultNum }) => {
  const cityButtonRef = useRef<HTMLButtonElement>(null);
  const cityModalContainerRef = useRef<HTMLDivElement>(null);
  const buildingTypeModalContainerRef = useRef<HTMLDivElement>(null);
  const sortingModalContainerRef = useRef<HTMLDivElement>(null);

  const { isOpen: isCityModalOpen, onOpen: onCityModalOpen, onClose: onCityModalClose } = useDisclosure();
  const {
    isOpen: isBuildingTypeModalOpen,
    onOpen: onBuildingTypeModalOpen,
    onClose: onBuildingTypeModalClose,
  } = useDisclosure();
  const { isOpen: isSortingModalOpen, onOpen: onSortingModalOpen, onClose: onSortingModalClose } = useDisclosure();

  const {
    finialAreaFilterResult,
    finialBuildTypeFilterResult,
    finalSortingType,
    finalListingStatusType,
    setAreaFilterResult,
    setBuildTypeFilterResult,
    setSortingType,
  } = useFilterListingContextProvider();

  const onAreaButtonPress = () => {
    if (isCityModalOpen) {
      onCityModalClose();
    } else {
      if (isBuildingTypeModalOpen) {
        onBuildingTypeModalClose();
      }
      if (isSortingModalOpen) {
        onSortingModalClose();
      }
      onCityModalOpen();
    }
  };
  const onBuildingTypeButtonPress = () => {
    if (isBuildingTypeModalOpen) {
      onBuildingTypeModalClose();
    } else {
      if (isCityModalOpen) {
        onCityModalClose();
      }
      if (isSortingModalOpen) {
        onSortingModalClose();
      }
      onBuildingTypeModalOpen();
    }
  };
  const onSortingButtonPress = () => {
    if (isSortingModalOpen) {
      onSortingModalClose();
    } else {
      if (isCityModalOpen) {
        onCityModalClose();
      }
      if (isBuildingTypeModalOpen) {
        onBuildingTypeModalClose();
      }
      onSortingModalOpen();
    }
  };
  const onClose = () => {
    onCityModalClose();
    onBuildingTypeModalClose();
    onSortingModalClose();
    setAreaFilterResult(finialAreaFilterResult);
    setBuildTypeFilterResult(finialBuildTypeFilterResult);
    setSortingType(finalSortingType);
  };

  const areaCountryTitleString = useMemo(() => {
    return CountryEnum[finialAreaFilterResult.country as keyof typeof CountryEnum];
  }, [finialAreaFilterResult]);
  const areaCityTitleString = useMemo(() => {
    if (finialAreaFilterResult.cities.length > 0) {
      return finialAreaFilterResult.cities.join(', ');
    } else {
      return '';
    }
  }, [finialAreaFilterResult]);

  const buildingTypeTitleString = useMemo(() => {
    if (finialBuildTypeFilterResult.length === 0) {
      return '全部類型';
    }
    return finialBuildTypeFilterResult.join(', ');
  }, [finialBuildTypeFilterResult]);
  const listingStatusTitleString = useMemo(() => {
    return getListingStatusTitleString(finalListingStatusType);
  }, [finalListingStatusType]);

  const cityFilterTop = useMemo(() => {
    return isCityModalOpen && cityModalContainerRef.current ? getTop(cityModalContainerRef.current) : 0;
  }, [isCityModalOpen]);

  const cityFilterLeft = useMemo(() => {
    return isCityModalOpen && cityModalContainerRef.current ? getLeft(cityModalContainerRef.current) : 0;
  }, [isCityModalOpen]);

  const buildTypeFilterTop = useMemo(() => {
    return isBuildingTypeModalOpen && buildingTypeModalContainerRef.current
      ? getTop(buildingTypeModalContainerRef.current)
      : 0;
  }, [isBuildingTypeModalOpen]);

  const buildTypeFilterLeft = useMemo(() => {
    return isBuildingTypeModalOpen && buildingTypeModalContainerRef.current
      ? getLeft(buildingTypeModalContainerRef.current)
      : 0;
  }, [isBuildingTypeModalOpen]);

  const sortingFilterTop = useMemo(() => {
    return isSortingModalOpen && buildingTypeModalContainerRef.current
      ? getTop(buildingTypeModalContainerRef.current)
      : 0;
  }, [isSortingModalOpen]);

  const sortingFilterRight = useMemo(() => {
    return isSortingModalOpen && sortingModalContainerRef.current ? getRight(sortingModalContainerRef.current) : 0;
  }, [isSortingModalOpen]);

  const sortingTypeDisplayValue = useMemo(() => {
    switch (finalSortingType) {
      case ListingSortingType.NONE:
        return '顯示排序';
      default:
        return sortingOptions.find((option) => option.value === finalSortingType)?.label;
    }
  }, [finalSortingType]);

  return (
    <Fragment>
      <Flex height={{ md: 'auto', base: '70px' }} my={{ md: 6 }}>
        <Flex
          position={{ base: 'fixed', md: 'relative' }}
          top={{ base: '60px', md: 'auto' }}
          mx={{ base: '-4', md: 0 }}
          mt={{ md: 1, base: 0 }}
          px={{ base: 0 }}
          zIndex={{ base: 50, md: 'auto' }}
          w={{ md: 'full' }}
        >
          <Flex direction={'row'} w={{ base: '100vh', md: 'full' }} alignItems={'center'} width={'100vw'}>
            <Flex
              id={'city-selector'}
              ref={cityModalContainerRef}
              className={'md:flex-[none] flex-1 flex-shrink-0 overflow-hidden'}
            >
              <Button
                ref={cityButtonRef}
                textColor={'black'}
                mr={{ md: 4 }}
                p={{ base: 4 }}
                py={{ md: 2 }}
                w={{ md: '20vw', base: '100%' }}
                h={'auto'}
                overflowX={'clip'}
                backgroundColor={'white'}
                borderColor={'black'}
                borderWidth={{ md: isCityModalOpen ? 2 : 1, base: 1 }}
                borderRight={{ md: isCityModalOpen ? '2px' : '1px', base: 0 }}
                borderLeft={{ md: isCityModalOpen ? '2px' : '1px', base: 0 }}
                onClick={onAreaButtonPress}
              >
                <HStack w={'full'} justify={'space-between'} align={'center'}>
                  <HStack className={'flex-1'} align={'center'} overflow={'hidden'}>
                    <Text>{areaCountryTitleString}</Text>
                    {areaCityTitleString != '' && (
                      <>
                        <Stack w={'1px'} h={'20px'} bgColor={'black'} />
                        <Text
                          overflow={'hidden'}
                          isTruncated
                          textOverflow={'ellipsis'}
                          textAlign={'left'}
                          className={'flex-1'}
                        >
                          {areaCityTitleString}
                        </Text>
                      </>
                    )}
                  </HStack>
                  <Stack marginLeft={'auto'}>
                    <ChevronDownIcon isActive={isCityModalOpen} />
                  </Stack>
                </HStack>
              </Button>
            </Flex>
            <Flex
              id={'building-type-selector'}
              ref={buildingTypeModalContainerRef}
              className={'md:flex-[none] flex-1 flex-shrink-0 overflow-hidden'}
            >
              <Button
                textColor={'black'}
                mr={{ md: 4 }}
                p={{ base: 4 }}
                py={{ md: 2 }}
                w={{ md: '20vw', base: '100%' }}
                h={'auto'}
                backgroundColor={'white'}
                borderColor={'black'}
                borderWidth={{ md: isBuildingTypeModalOpen ? 2 : 1, base: 1 }}
                borderRight={{ md: isBuildingTypeModalOpen ? '2px' : '1px', base: 0 }}
                onClick={onBuildingTypeButtonPress}
              >
                <HStack w={'full'} justify={'space-between'}>
                  <Text textAlign={'left'} className={'flex-1'}>
                    {buildingTypeTitleString}
                    {listingStatusTitleString && `(${listingStatusTitleString})`}
                  </Text>
                  <ChevronDownIcon isActive={isBuildingTypeModalOpen} />
                </HStack>
              </Button>
            </Flex>
            <Flex
              id={'sorting-selector'}
              ref={sortingModalContainerRef}
              className={classNames(
                'md:flex-[none]',
                'md:ml-auto',
                'w-[58px] md:w-[184px] h-[58px] md:h-[42px]',
                'flex-shrink-0 overflow-hidden'
              )}
            >
              <Flex
                as={'button'}
                justifyContent={{ md: 'space-between', base: 'center' }}
                alignItems={'center'}
                className={'w-full h-full md:px-4'}
                borderColor={'black'}
                backgroundColor={'white'}
                borderWidth={{ md: isBuildingTypeModalOpen ? 2 : 1, base: 1 }}
                borderRight={{ md: isBuildingTypeModalOpen ? '2px' : '1px', base: 0 }}
                onClick={onSortingButtonPress}
              >
                <Text as={'span'} className={'text-sm'} display={{ base: 'none', md: 'block' }}>
                  {sortingTypeDisplayValue}
                </Text>
                <BsFilterLeft size={24} />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* Location filter */}
      <Modal onClose={onClose} isOpen={isCityModalOpen}>
        <ModalContent
          position={{ md: 'absolute', base: 'fixed' }}
          top={{ md: `${cityFilterTop}px` }}
          left={{ md: `${cityFilterLeft}px`, base: 0 }}
          bottom={{ md: 'auto', base: 0 }}
          m={{ base: 0 }}
          width={{ base: '100vw', md: 'auto' }}
          maxWidth={{ md: 'auto' }}
          height={{ base: '100vh', md: 'auto' }}
          alignSelf={'self-start'}
          borderRadius={0}
          background={{ base: 'rgba(28, 30, 33, 0.4)' }}
          zIndex={{ base: 99999 }}
        >
          <BuyFilterAreaModal
            citiesData={citiesData}
            usCitiesData={usCitiesData}
            resultNum={resultNum ?? 0}
            isOpen={isCityModalOpen}
            onClose={onCityModalClose}
          />
        </ModalContent>
      </Modal>
      {/* Build type filter */}
      <Modal onClose={onClose} isOpen={isBuildingTypeModalOpen}>
        <ModalContent
          position={{ md: 'absolute', base: 'fixed' }}
          top={{ md: `${buildTypeFilterTop}px` }}
          left={{ md: `${buildTypeFilterLeft}px` }}
          bottom={{ md: 'auto', base: 0 }}
          m={{ base: 0 }}
          width={{ base: '100vw', md: 'auto' }}
          maxWidth={{ md: 'auto' }}
          height={{ base: '100vh', md: 'auto' }}
          alignSelf={'self-start'}
          borderRadius={0}
          background={{ base: 'rgba(28, 30, 33, 0.4)' }}
          zIndex={{ base: 99999 }}
        >
          <BuildingTypeFilterModal
            buildingTypesData={buildingTypesData}
            resultNum={resultNum ?? 0}
            isOpen={isBuildingTypeModalOpen}
            onClose={onBuildingTypeModalClose}
          />
        </ModalContent>
      </Modal>
      {/* Sorting */}
      <Modal onClose={onClose} isOpen={isSortingModalOpen}>
        <ModalContent
          position={{ md: 'absolute', base: 'fixed' }}
          top={{ md: `${sortingFilterTop}px` }}
          right={{ md: `${sortingFilterRight}px` }}
          bottom={{ md: 'auto', base: 0 }}
          m={{ base: 0 }}
          width={{ base: '100vw', md: 'auto' }}
          maxWidth={{ md: 'auto' }}
          height={{ base: '100vh', md: 'auto' }}
          alignSelf={'self-start'}
          borderRadius={0}
          background={{ base: 'rgba(28, 30, 33, 0.4)' }}
          zIndex={{ base: 99999 }}
        >
          <BuyPageSortingModal onClose={onSortingModalClose} />
        </ModalContent>
      </Modal>
    </Fragment>
  );
};
export default FilterContainer;

export const ChevronDownIcon: FC<{ isActive: boolean }> = ({ isActive }) => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classNames('transform transition-transform', { 'rotate-180': isActive })}
    >
      <path d='m17.09 8.59-4.59 4.58-4.59-4.58L6.5 10l6 6 6-6-1.41-1.41z' fill='#212121' />
    </svg>
  );
};
