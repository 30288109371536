import { FC } from 'react';
import Image from 'next/image';
import { Button, Flex, HStack, ModalBody, Text } from '@chakra-ui/react';

import { useFilterListingContextProvider } from '../../context/FilterListingContext';

const BuyPageFilterModal: FC<{ onClose: () => void; onReset: () => void }> = ({ onClose, onReset, children }) => {
  const { applyFilter, resultNum } = useFilterListingContextProvider();

  return (
    <ModalBody
      display={'flex'}
      position={{ base: 'absolute', md: 'relative' }}
      bottom={{ base: 0 }}
      left={{ base: 0 }}
      px={{ base: 0 }}
      py={{ base: 4, md: 0 }}
      height={{ base: 'fit-content', md: 'auto' }}
      maxHeight={{ base: '80vh' }}
      width={{ md: '624px' }}
      minWidth={{ md: 'auto', base: '100vw' }}
      backgroundColor={'white'}
      className={'shadow-dropdown overflow-hidden'}
    >
      <Flex w={'full'} flexDirection={'column'} className={'flex-1 overflow-hidden'}>
        <Flex
          flexDirection={'column'}
          w={'full'}
          px={{ md: 8, base: 6 }}
          pt={{ md: 4 }}
          className={'flex-1 overflow-hidden'}
        >
          <Flex
            as={'button'}
            onClick={onClose}
            className={'w-6 h-10 py-2'}
            display={{ md: 'none' }}
            mb={4}
            alignItems={'center'}
            justifyContent={'flex-start'}
          >
            <Image src={'/close-outline.svg'} layout={'fixed'} width={24} height={24} />
          </Flex>
          {children}
          <Text fontSize={'xs'} color={'gray.500'} bg={{ base: 'gray.100', md: 'transparent' }} mx={-6} px={6} py={2}>
            篩選不到合適物件？撥打 02-7752-3755，我們將提供高效專業的買代尋服務。
          </Text>
        </Flex>
        <HStack
          w={'full'}
          justify={'space-between'}
          borderTop={1}
          borderStyle={'solid'}
          borderColor={'gray.300'}
          py={{ md: 4, base: 2 }}
          px={{ md: 8, base: 6 }}
          className={'flex-shrink-0'}
        >
          <Button
            bgColor={'white'}
            fontSize={{ base: '16px' }}
            textColor={'darkgray'}
            textDecorationLine={'underline'}
            onClick={onReset}
            px={0}
            m={0}
          >
            <Text fontWeight={'500'}>重新設定</Text>
          </Button>
          <Button
            px={{ base: 8 }}
            py={{ base: 3 }}
            fontSize={{ base: '16px' }}
            textColor={'white'}
            onClick={() => {
              applyFilter();
              onClose();
            }}
          >
            查看 {resultNum} 筆結果
          </Button>
        </HStack>
      </Flex>
    </ModalBody>
  );
};
export default BuyPageFilterModal;
